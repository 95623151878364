import { render, staticRenderFns } from "./OrdersStatistics.vue?vue&type=template&id=069b8d40&scoped=true&"
import script from "./OrdersStatistics.vue?vue&type=script&lang=js&"
export * from "./OrdersStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069b8d40",
  null
  
)

export default component.exports