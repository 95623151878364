<template>
    <div id="orders-statistics">
        订单统计
    </div>
</template>

<script>
export default {
    name: 'OrdersStatistics', // 订单统计
}
</script>

<style lang="less" scoped>
</style>